<template>
  <div>
    <!-- FILTER START V1 -->
    <b-card v-if="filterSelect.api.value == 'transaction/bycurrency'">
      <b-row>
        <!-- <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.datefrom") }}</label>

            <flat-pickr
              v-model="filterSelect.dateFrom"
              class="form-control"
              :config="{
                altInput: true,`
                altFormat: `d-m-Y H${
                  filterSelect.api.value == 'v2/report/bycurrency' ? '' : ':i'
                }`,
                enableTime: true,
                time_24hr: true,
              }"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="4">
          <b-form-group rules="required">
            <label class="mr-1">{{ $t("labels.dateto") }}</label>
            <flat-pickr v-model="filterSelect.dateTo" class="form-control" :config="{
              altInput: true,
              altFormat: `d-m-Y H${filterSelect.api.value == 'v2/report/bycurrency' ? '' : ':i'
                }`,
              enableTime: true,
              time_24hr: true,
            }" />
            <!-- <label class="mr-1 text-danger" v-if="filterSelect.dateFrom"
              >Rango maximo de 30 dias
            </label> -->
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group>
            <label class="mr-1 text-lg" for="customRadioInline1">Clientes
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.customer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="customerOptions" label="name" @option:selected="selectCustomer"
              :reduce="(customer) => customer._id" placeholder="Seleccione un cliente" value="">
              <template #option="{ name }">
                <feather-icon icon="UserIcon" size="16" class="align-middle mr-50" />
                <span> {{ name }}</span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">WL </label>
            <v-select v-model="filterSelect.WL" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="wlOptions"
              label="name" :reduce="(wl) => wl._id" placeholder="Select WL" value="" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1" for="customRadioInline1">Productos
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.product" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productsOptions" label="name" @input="inputSelect" :reduce="(product) => product.productId"
              placeholder="Select product" value="" />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">
              Monedas
              <span class="text-danger">*</span>
            </label>
            <v-select v-model="filterSelect.currency" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currencyssOptions" placeholder="Seleccione la moneda" value="Bs">
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label class="mr-1">Api</label>
            <v-select v-model="filterSelect.api" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="apisOptions" placeholder="Seleccione la moneda" value="api" />
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center justify-content-end">
          <b-col md="6">
            <b-form-group>
              <label class="mr-1">Buscador</label>
              <b-form-input v-model="filterSelect.q" class="d-inline-block" :placeholder="$t('labels.search')" />
            </b-form-group>
          </b-col>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col class="d-flex align-items-center justify-content-end">
          <b-button :disabled="loadingData" @click="inputSelect()" class="text-nowrap" variant="primary">
            <b-spinner small v-if="loadingData"></b-spinner>
            <feather-icon v-if="!loadingData" icon="SearchIcon" class="mr-50" />
            <span v-if="!loadingData" class="text-nowrap">{{
              $t("labels.search")
            }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- FILTER END V1-->

    <!-- FILTER START V2 -->

    <b-card v-else>
      <!-- src\@core\components\filter-reports\default.vue -->
      <filter-reports-default :isFilterByPlayer="false" @getReportEvent="getReportEvent" :apisOptions="apisOptions" />
    </b-card>

    <!-- FILTER END V2 -->

    <b-row>
      <b-col v-for="(item, index) in cardCabezera" :key="index" lg="3" sm="6">
        <statistic-card-horizontal :color="item.color" :icon="item.icon" :statistic="item.statistic"
          :statistic-title="item.title" />
      </b-col>
    </b-row>

    <b-card>
      <b-overlay :show="loadingData" variant="transparent" no-wrap />
      <b-modal id="modalResponse" title="PopUp Response" cancel-variant="outline-secondary" scrollable>
        <b-card-text>
          {{ rows }}
        </b-card-text>
      </b-modal>

      <b-row class="mb-1">
        <b-col md="6"></b-col>
        <b-col md="6" class="d-flex align-items-center justify-content-end">
          <b-button @click="downloadFile()" class="text-nowrap"
            :variant="tableData.length > 0 ? 'primary' : 'secondary'" :disabled="tableData.length > 0 ? false : true">
            <b-spinner small v-if="exportDataLoading"></b-spinner>
            <feather-icon v-if="!exportDataLoading" :icon="tableData.length > 0 ? 'DownloadIcon' : 'XIcon'"
              class="mr-50" />
            <span v-if="!exportDataLoading" class="text-nowrap">{{
              $t("labels.export")
            }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- table -->
      <vue-good-table mode="remote" :columns="columns" :rows="tableData" :totalRows="totalRows"
        :pagination-options="{ enabled: true }" :sort-options="{
          enabled: true,
          initialSortBy: [{ field: 'totalSpins', type: 'desc' }],
        }" :line-numbers="true" styleClass="vgt-table bordered striped hover-rows" @on-sort-change="onSortChange">
        <template slot="table-row" slot-scope="props">
          <div v-if="props.column.field == 'brands'" class="d-flex align-items-center flex-wrap">
            <b-badge v-for="(brand, index) in props.row.brands" :key="index" variant="light-primary" class="mr-1 mb-1">
              <feather-icon icon="BriefcaseIcon" size="14" class="mr-50 text-primary" />
              {{ brand }}
            </b-badge>
            <span v-if="!props.row.brands || props.row.brands.length === 0" class="text-muted">
              No brands
            </span>
          </div>

          <div v-else-if="props.column.field == 'products'" class="d-flex align-items-center flex-wrap">
            <b-badge v-for="(product, index) in props.row.products" :key="index" variant="light-warning"
              class="mr-1 mb-1">
              <feather-icon icon="PackageIcon" size="14" class="mr-50 text-warning" />
              {{ product }}
            </b-badge>
          </div>

          <div v-else-if="props.column.field == 'customers'" class="d-flex align-items-center flex-wrap">
            <b-badge v-for="(customer, index) in props.row.customers" :key="index" variant="light-info"
              class="mr-1 mb-1 cursor-pointer" @click="navigateToCustomer(customer._id)">
              <feather-icon icon="UserIcon" size="14" class="mr-50" />
              {{ customer }}
            </b-badge>
          </div>

          <div v-else-if="props.column.field == 'currency'" class="text-center">
            <b-badge variant="light-info" pill>{{ props.row.currency }}</b-badge>
          </div>

          <div v-else-if="props.column.field == 'totalSpins'" class="text-right font-weight-bold">
            <feather-icon icon="RepeatIcon" size="14" class="mr-50 text-muted" />
            {{ props.row.totalSpins }}
          </div>

          <div v-else-if="props.column.field == 'totalBet'" class="text-right">
            <div class="font-weight-bold">
              {{ props.row.totalBet | currency({ symbol: "" }) }} {{ props.row.currency }}
            </div>
            <small class="text-muted">
              {{ totalSalesToUSD(props.row) | currency({ symbol: "USD " }) }}
            </small>
          </div>

          <div v-else-if="props.column.field == 'totalWins'" class="text-right">
            <div class="font-weight-bold">
              {{ props.row.totalWins | currency({ symbol: "" }) }} {{ props.row.currency }}
            </div>
            <small class="text-muted">
              {{ totalWinsToUSD(props.row) | currency({ symbol: "USD " }) }}
            </small>
          </div>

          <div v-else-if="props.column.field == 'totalProfit'" class="text-right">
            <div :class="props.row.totalProfit >= 0 ? 'text-success' : 'text-danger'">
              <strong>{{ props.row.totalProfit | currency({ symbol: "" }) }} {{ props.row.currency }}</strong>
            </div>
            <small :class="profitToUSD(props.row) >= 0 ? 'text-success' : 'text-danger'">
              {{ profitToUSD(props.row) | currency({ symbol: "USD " }) }}
            </small>
          </div>

          <div v-else-if="props.column.field == 'countRecords'" class="text-right">
            <b-badge variant="light-secondary">{{ props.row.countRecords }}</b-badge>
          </div>
        </template>

        <!-- Empty state -->
        <template slot="emptystate">
          <div class="text-center p-4">
            <feather-icon icon="SearchIcon" size="24" class="mb-2 text-muted" />
            <h5>No data found</h5>
            <p class="text-muted">Try adjusting your search or filter to find what you're looking for.</p>
          </div>
        </template>

        <!-- Column header -->
        <template slot="table-column" slot-scope="props">
          <div class="d-flex align-items-center">
            <span v-if="props.column.field === 'brands'">
              <feather-icon icon="BriefcaseIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'products'">
              <feather-icon icon="PackageIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'customers'">
              <feather-icon icon="UsersIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'currency'">
              <feather-icon icon="DollarSignIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'totalSpins'">
              <feather-icon icon="RepeatIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'totalBet'">
              <feather-icon icon="TrendingUpIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'totalWins'">
              <feather-icon icon="AwardIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'totalProfit'">
              <feather-icon icon="PieChartIcon" size="14" class="mr-50" />
            </span>
            <span v-else-if="props.column.field === 'countRecords'">
              <feather-icon icon="HashIcon" size="14" class="mr-50" />
            </span>
            {{ props.column.label }}
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap mr-1">Show</span>
              <b-form-select v-model="serverParams.limit" :options="pages" class="mx-1 per-page-selector"
                @input="onPerPageChange" />
              <span class="text-nowrap">entries of {{ props.total }}</span>
            </div>

            <!-- pagination -->
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="serverParams.limit" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @change="onPageChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>

      <hr />

      <b-card no-body class="mt-3">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="mb-0">
            <feather-icon icon="DollarSignIcon" size="20" class="text-primary mr-1" />
            Totales USD
          </h4>
        </div>
        <div>
          <vue-good-table v-if="!loadingData" compactMode :columns="columnsTotalUSD" :rows="dataItemsTotalsUSD"
            :pagination-options="{ enabled: false }" :sort-options="{ enabled: false }" styleClass="vgt-table bordered">
            <div slot="emptystate" class="text-center p-3">
              <feather-icon icon="AlertCircleIcon" size="18" class="mb-1 text-muted" />
              <p class="mb-0">No hay datos para mostrar</p>
            </div>

            <template slot="table-row" slot-scope="props">
              <div v-if="props.column.field == 'sales'" class="text-right">
                <strong>{{ props.row.sales | currency({ symbol: "USD " }) }}</strong>
              </div>
              <div v-if="props.column.field == 'wins'" class="text-right">
                <strong>{{ props.row.wins | currency({ symbol: "USD " }) }}</strong>
              </div>
              <div v-if="props.column.field == 'profit'" class="text-right">
                <strong :class="props.row.profit >= 0 ? 'text-success' : 'text-danger'">
                  {{ props.row.profit | currency({ symbol: "USD " }) }}
                </strong>
              </div>
            </template>

            <template slot="table-column" slot-scope="props">
              <div class="font-weight-bold">
                <span v-if="props.column.field === 'sales'">
                  <feather-icon icon="TrendingUpIcon" size="14" class="mr-50" />
                </span>
                <span v-else-if="props.column.field === 'wins'">
                  <feather-icon icon="AwardIcon" size="14" class="mr-50" />
                </span>
                <span v-else-if="props.column.field === 'profit'">
                  <feather-icon icon="PieChartIcon" size="14" class="mr-50" />
                </span>
                {{ props.column.label }}
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
  BSpinner,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import store from "@/store";
import reportsStoreModule from "@/store/reports";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { formatDate } from "@core/utils/filter";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import StatisticCardWithLineChart from "@core/components/statistics-cards/StatisticCardWithLineChart.vue";
import filterReportsDefault from "@core/components/filter-reports/default.vue";
import moment from "moment";
import { mapActions } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    BSpinner,
    ToastificationContent,
    StatisticCardHorizontal,
    StatisticCardWithAreaChart,
    StatisticCardWithLineChart,
    filterReportsDefault,
    BBadge
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      exportDataLoading: false,
      timeOutSearchCustomer: null,
      showFilter: false,
      loadingData: false,
      tableData: [],
      pages: [20, 40, 60, 100, 120, 200, 500, 1000],
      columns: [
        { label: "Currency", field: "currency", sortable: false },
        { label: "Brands", field: "brands", sortable: false },
        { label: "Products", field: "products", sortable: false },
        { label: "Customers", field: "customers", sortable: false },
        { label: "Spins", field: "totalSpins" },
        { label: "Sales", field: "totalBet" },
        { label: "Wins", field: "totalWins" },
        { label: "Profit", field: "totalProfit" },
      ],
      rows: [],
      serverParams: {
        page: 1,
        limit: 100,
      },
      totalRows: 0,
      customerOptions: [],
      productsOptions: [],
      apisOptions: [
        { label: "Api V1", value: "transaction/bycurrency" },
        { label: "Api V2", value: "v2/report/bycurrency" },
      ],
      currencyssOptions: [
        "ARS",
        "BRL",
        "CLP",
        "COP",
        "EUR",
        "MXN",
        "MYR",
        "PEN",
        "PYG",
        "USD",
        "UYU",
        "VES",
        "XAF",
      ],
      currencies: [],
      filterSelect: {
        dateTo: new Date(),
        dateFrom: new Date(),
        customer: "",
        product: "",
        currency: "",
        q: "",
        brand: "",
        WL: "",
        api: { label: "Api V2", value: "v2/report/bycurrency" },
      },
      timeoutGetTransactions: null,
      wlOptions: [],
      loadingWlOptions: false,
      totals: {
        totalSpins: 0,
        totalSales: 0,
        totalWins: 0,
        totalProfit: 0,
      },
      minDate: null,
      maxDate: null,
      columnsTotalUSD: [
        { label: "Ventas", field: "sales" },
        { label: "Ganadas", field: "wins" },
        { label: "Ganancia", field: "profit" },
      ],
      dataItemsTotalsUSD: [
        {
          sales: 0,
          wins: 0,
          profit: 0,
        },
      ],
    };
  },
  created() {
    this.filterSelect.dateFrom = moment()
      .subtract(29, "days")
      .format("YYYY-MM-DD 00:00");
    this.filterSelect.dateTo = moment().format("YYYY-MM-DD 23:59");
    this.minDate = moment().subtract(29, "days").format("YYYY-MM-DD 00:00");
    this.maxDate = moment().format("YYYY-MM-DD 23:59");
    this.getExchangeRates();
  },
  computed: {
    cardCabezera() {
      return [
        {
          statistic: this.totals.totalSpins,
          color: "success",
          icon: "PlayCircleIcon",
          title: "Total Spins",
        },
        {
          statistic: this.totals.totalSales,
          color: "danger",
          icon: "ActivityIcon",
          title: "Total Sales",
        },
        {
          statistic: this.totals.totalWins,
          color: "success",
          icon: "TrophyIcon",
          title: "Total Wins",
        },
        {
          statistic: this.totals.totalProfit,
          color: "danger",
          icon: "DollarSignIcon",
          title: "Total Profit",
        },
      ];
    },
    typeUserLoged() {
      return this.$store.getters["usersModule/typeUser"];
    },
    userLoged() {
      return this.$store.getters["usersModule/userLoged"];
    },
  },
  // add filters
  filters: {
    formatDate,
  },

  methods: {
    ...mapActions("reports", ["downloadReportFile"]),

    onSortChange(params) {
      this.serverParams.sorts = params;
      this.inputSelect();
    },
    totalWinsToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.totalWinsUSD = row.totalWins / data.rate;
          return row.totalWinsUSD;
        } else if (row.currency == "USD") {
          row.totalWinsUSD = row.totalWins;
          return row.totalWinsUSD;
        }
      }
    },
    totalSalesToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.totalSalesUSD = row.totalBet / data.rate;
          return row.totalSalesUSD;
        } else if (row.currency == "USD") {
          row.totalSalesUSD = row.totalBet;
          return row.totalSalesUSD;
        }
      }
    },
    profitToUSD(row) {
      for (let data of this.currencies) {
        if (row.currency == data.currency) {
          row.profitUSD = row.totalProfit / data.rate;
          return row.profitUSD;
        } else if (row.currency == "USD") {
          row.profitUSD = row.totalProfit;
          return row.profitUSD;
        }
      }
    },
    async getExchangeRates() {
      const res = await this.$store.dispatch("currenciesModules/getCurrencies");
      this.currencies = res;
    },
    downloadFile() {
      this.exportDataLoading = true;
      const VUE_APP_GATEWAY = process.env.VUE_APP_GATEWAY;
      const filter = {
        dateFrom: moment(this.filterSelect.dateFrom)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        dateAt: moment(this.filterSelect.dateTo)
          .utc()
          .format("YYYY-MM-DD HH:mm"),
        customerId: this.filterSelect.selectCustomer,
        currency: this.filterSelect.currency,
        product: this.filterSelect.product,
        WL: this.filterSelect.WL,
      };
      const finalUrl = VUE_APP_GATEWAY + "v2/report/bycurrency";
      const format = ".xlsx";
      const customerName = this.customerOptions.find(
        (customer) => customer._id == filter.customerId
      );
      const fileTitle = `profit-games-${filter.product
        }-${customerName?.name.toUpperCase()}-(${filter.dateFrom + " - " + filter.dateAt
        })${format}`;

      this.downloadReportFile({
        filter: {
          ...this.filterSelect,
          ...this.serverParams,
        },
        fileTitle: fileTitle,
        url: finalUrl,
      })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("labels.errorDownloadFile"),
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.message}`,
            },
          });
        })
        .finally(() => (this.exportDataLoading = false));
    },
    async searchCustomer(search, loading) {
      loading(true);
      try {
        clearTimeout(this.timeOutSearchCustomer);

        this.timeOutSearchCustomer = setTimeout(async () => {
          const res = await this.$store.dispatch(
            "customerModule/getCustomers",
            {
              q: search,
              paginate: {
                page: 1,
                perPage: 20,
                sortDesc: true,
              },
            }
          );
          this.customerOptions = res.data.customers;
          loading(false);
        }, 500);

        loading(false);
      } catch (error) {
        console.log(error);
      }
    },
    async getTransactions() {
      try {
        this.loadingData = true;
        const res = await this.$store.dispatch(
          "reports/getTransactionsbyCurrency",
          {
            ...this.filterSelect,
            ...this.serverParams,
          }
        );

        this.tableData = res.data.data;

        // Resetear totales USD
        this.dataItemsTotalsUSD[0] = {
          sales: 0,
          wins: 0,
          profit: 0,
        };

        // Redondear los totales para mejor visualización (opcional)
        this.dataItemsTotalsUSD[0].sales = parseFloat(res.data.totals.totalBet.toFixed(2));
        this.dataItemsTotalsUSD[0].wins = parseFloat(res.data.totals.totalWins.toFixed(2));
        this.dataItemsTotalsUSD[0].profit = parseFloat(res.data.totals.totalProfit.toFixed(2));


        this.totalRows = res.data.countDocuments;
        this.totals = {
          totalSpins: res.data.totals.totalSpins,
          totalSales: res.data.totals.totalBet,
          totalWins: res.data.totals.totalWins,
          totalProfit: res.data.totals.totalProfit
        };
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching transactions list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    async getWlOptions() {
      try {
        this.loadingWlOptions = true;
        const customerSelected = this.customerOptions.find(
          (customer) => customer._id == this.filterSelect.selectCustomer
        );
        const res = await this.$store.dispatch("reports/getWlOptions", {
          customerId: customerSelected.customerId,
        });
        this.wlOptions = res.data;
        this.filterSelect.WL = "";
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingWlOptions = false;
      }
    },
    fillZero(number, length) {
      var my_string = "" + number;
      while (my_string.length < length) {
        my_string = "0" + my_string;
      }

      return my_string;
    },
    onPageChange(page) {
      this.serverParams.page = page;
      this.getTransactions();
    },
    onPerPageChange(limit) {
      this.serverParams.page = 1;
      this.serverParams.limit = limit;
      this.getTransactions();
    },
    getReportEvent(filters) {
      this.filterSelect = filters;
      this.inputSelect();
    },
    inputSelect() {
      if (
        (!this.filterSelect.customer && this.typeUserLoged == "Root") ||
        !this.filterSelect.product ||
        this.filterSelect.product?.length < 1 ||
        this.filterSelect.customer?.length < 1
      )
        return;

      this.serverParams.page = 1;
      clearTimeout(this.timeoutGetTransactions);
      this.timeoutGetTransactions = setTimeout(() => {
        this.getTransactions();
      }, 500);
    },

    selectCustomer(customer) {
      this.productsOptions = customer.products;
      this.filterSelect.product = "";
      if (customer.products && customer.products.length >= 1) {
        this.filterSelect.product = customer.products[0].productId;
      }
      this.getWlOptions();
    },
    async getCustomer() {
      const res = await this.$store.dispatch("customerModule/getCustomers", {
        paginate: {
          page: 1,
          perPage: 200,
          sortDesc: true,
        },
        isMinimumData: true,
      });
      this.customerOptions = res.data.customers;
    },
    async getProducts() {
      const res = await this.$store.dispatch("reports/getProducts");
      this.productsOptions = res.data;
    },
    navigateToCustomer(customerId) {
      // Si tenemos el ID completo del cliente, podemos navegar a su página de detalle
      if (customerId) {
        this.$router.push({ name: 'customer-detail', params: { id: customerId } });
      }
    },
  },
  mounted() {
    // Register module
    if (!store.hasModule("reports"))
      store.registerModule("reports", reportsStoreModule);
    this.getProducts();
  },
  destroyed() {
    if (store.hasModule("reports")) store.unregisterModule("reports");
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
